`<template>
  <div>
	<!--  Content Start -->
	<section class="sabc_tv_schedule_sec">
	  <div class="container">
		<div class="row">
		  <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
			<!-- TV Live start -->
			<h1>Live TV</h1>
			<!-- <div class="text-center" v-if="!is_data_ready">
			  <div class="spinner-border" role="status">
				<span class="sr-only">Loading...</span>
			  </div>
			</div> -->
			<div>
			  <!-- TV Live logo start -->
			  <div class="sabc_tv_schedule_logo">
				<ul>
				  <li v-for="channel in tvchannel" :class=" $route.params.channel == channel.tvlive_slug ? 'selected selected-logo' : ''" :key="channel.id">
					<router-link :to="{ name: 'tv_live', params: { channel: channel.tvlive_slug }, }">
					  <img v-if="channel.image != null" :src="$resizeImage(channel.image.image_path, 188, 70)" :alt="channel.name" width="188" height="70" />
					  <img v-else :src="require('@/assets/images/sabc_tv_schedule_sport_sabc_unselect.svg')" alt="SABC 1 Channel Logo" width="188" height="70">
					</router-link>
				  </li>
				</ul>
			  </div>
			  <!-- TV Live tabs -->
			  <!-- TV Channel Embed area -->

			  <!-- <div class="live_tv_streaming_loading">
				<img :src="require('@/assets/images/sabc_main_logo.gif')" alt="TV Loading" height="64" width="249"/>
			  </div> -->
			  <div class="sabc_tv_vieo_ad_section">
				<div class="sabc_ad_web_section">
				</div>
				<div class="sabc_tv_vieo_section">
					<div class="sabc_tv_programm_list sabc_tv_programm_list_embed">	
						<div v-if="!is_data_ready"  class="TV_loader_class">
							<img :src="require('@/assets/images/sabc_main_logo.gif')" alt="TV Loading" height="64" width="249"/>
						</div>
						<div class="channel_embed_area" v-html="channelEmbed"></div>
					</div>
				</div>
				<div class="sabc_ad_web_section">
				</div>
			  </div>
			  <div class="sabc_ad_mobile_section">
			  </div>
			  <div class="sabc_tv_programm_list sabc_tv_live_sec">
				<div class="sabc_tv_programm_row " v-for="episode in program" :class=" episode.live == 1 ? 'sabc_tv_programm_live' : ''">
				  <div class="sabc_tv_programm_timestatus">
					<label v-if="episode.live"><img :src="require('@/assets/images/sabc_live_icon.webp')" alt="LIVE"> LIVE </label>
					<span v-else>UP NEXT</span>
					<!-- <p>{{episode.time_slot}}</p> -->
					<p>{{episode.start_time | formatDateTimeMeridiem}} - {{episode.end_time | formatDateTimeMeridiem}}</p>
				  </div>
				  <div class="sabc_tv_programm_dtl">
					<h2>{{episode.episode_title}}</h2>
					<p>{{episode.description}}</p>
					<router-link :to="{ name: 'tv_live'}" v-if ="episode.live"><img :src="require('@/assets/images/sbac_hdr_watchvideobtn.webp')" alt="Watch Button" />Watch</router-link>
				  </div>
				</div>
			  </div>
			  <router-link class="tvlive-full-schedule" :to="{ name: 'tv_schedule'}" > View full schedule</router-link>
			  <!-- tv schedule end -->
			</div>
		  </div>
		</div>
	  </div>
	</section>
  </div>
  <!--  Content end -->
</template>

<script>
export default {
  name: "tv-live",
  data() {
	return {
	  is_data_ready: false,
	  selectedChannel: 1,
	  tvchannel: [],
	  tvChannelEmbed: [],
	  program: [],
	  channelEmbed: "",
	};
  },
  components: {},
  mounted() {
	this.getTvData();
  },
  watch: {
	$route(to, from) {
	  if (this.$route.params.channel == undefined) {
		this.getTvData();
	  } else {
		this.channelEmbed = "";
		this.channelEmbed = this.tvChannelEmbed[this.$route.params.channel];
	  }
	},
  },
  serverPrefetch() {
	this.getTvData();
  },
  computed: {},
  metaInfo() {
	if (this.$store.state.siteSetting) {
	  let ss = this.$store.state.siteSetting;

	  let title = this.$getSafe(() => ss.live_meta_title);
	  let description = this.$getSafe(() => ss.live_meta_description);
	  let keywords = this.$getSafe(() => ss.live_meta_keywords);

	  return {
		title: title,
		description: description,
		meta: this.$getMetaTags(title, description, keywords),
		link: [
		  { rel: "canonical", href: this.$toFullPath(this.$route.fullPath) },
		],
	  };
	}
  },

  methods: {
	getTvData() {
	  this.is_data_ready = false;
	  return this.$axios
		.get(this.$GetLiveTvData)
		.then((response) => {
		  if (response.data) {
			this.tvchannel = response.data.channeldetail;
			this.tvChannelEmbed = response.data.embed;
			this.program = response.data.program;
			if (this.$route.params.channel !== undefined) {
			  this.channelEmbed =
				this.tvChannelEmbed[this.$route.params.channel];
			} else {
			  if (this.tvchannel[0] !== undefined) {
				this.channelEmbed = this.tvchannel[0].embed;
				this.$route.params.channel = this.tvchannel[0].tvlive_slug;				
			  }else{
				this.channelEmbed = 'No Channel Available';
			  }
			}
			setTimeout(() => {
				this.is_data_ready = true;
			}, "5000")
		  }
		})
		.catch((error) => {});
	},
	setChannelEmbed() {
	  this.channelEmbed =
		'<iframe src=" https://globecast.co.za/Planet_Sport_SABC1/embed.html" title="Channel Embed Code" width="100%" height="500px"></iframe>';
	},
	showEpisodeTime(value){
		// return moment(String(value)).format('YYYY-mm-dd hh:mmA')
		return value;
	},
	
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/tv_schedule.scss";

</style>